














import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { Modal } from '@/app_code/Modals/Modal'
import BusinessBranchPickerModalOptions from '@/app_code/Modals/Options/BusinessBranchPickerModalOptions'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { FranchiseModel } from 'truemarket-modules/src/models/api/admin'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class Index extends Vue {
  private isAddingFranchise = false

  private addCountryName = ''

  private franchises: FranchiseModel[] = []

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.FranchiseId, [
    new DataTableColDef('ABN', row => row.Business.ABN),
    new DataTableColDef('Business', row => row.Business.Business.Name),
    new DataTableColDef('Branch', row => row.Business.TradingName),
    new DataTableColDef('Zones', row => `${row.Zones.length} ${row.Zones.length === 1 ? 'Zone' : 'Zones'}`)
  ]))

  loadFranchises () {
    Services.API.Admin.Franchises.GetFranchises().then((franchises) => {
      this.franchises = franchises
    })
  }

  mounted () {
    this.loadFranchises()
  }

  addFranchise () {
    Modal.ShowPredefined<BusinessBranchPickerModalOptions>(new BusinessBranchPickerModalOptions({
      Callback: (branchId: string) => {
        Services.API.Businesses.GetBranch(branchId).then((branch) => {
          Modal.Confirm({ Content: `Do you want to make ${branch.TradingName} (ABN: ${branch.ABN}) a franchisee?` }).then(() => {
            Services.API.Admin.Franchises.Create(branchId).then(() => {
              this.loadFranchises()
            })
          })
        })
      }
    }))
  }

  editFranchise (franchiseId: string) {
    this.$router.push(`/dashboard/admin/franchises/${franchiseId}`)
  }
}
