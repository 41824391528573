import { BusinessBranchStatus } from 'truemarket-modules/src/models/api/businesses'
import ModalOptions from './ModalOptions'

class BusinessBranchPickerModalOptions extends ModalOptions {
  IsFranchise?: boolean
  Statuses?: BusinessBranchStatus[]
  FranchiseId?: string
  Callback?: (branchId: string) => void;

  constructor (init?: Partial<BusinessBranchPickerModalOptions>) {
    super('BusinessBranchPicker')

    if (init) Object.assign(this, init)
  }
}

export default BusinessBranchPickerModalOptions
